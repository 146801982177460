.form {
  &__group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  &__success {
    text-align: center;
    margin-bottom: 20px;
    color: #238c00;
  }

  &__label {
    margin-bottom: 14px;

    font-size: .93rem;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: left;

    color: #a0a8b3;

    span {
      color: rgb(190, 67, 67);
    }
  }

  &__input, &__textarea {
    height: 55px;
    padding: 0 15px;

    letter-spacing: 1px;
    color: #3c3e41;

    border-radius: 6px;
    background-color: #fdfefe;
    border: 2px solid #dadada;
    transition: .4s;

    &:active {
      border: 2px solid #279ffe;
      transition: .4s;
    }
  }

  &__textarea {
    padding: 15px;
    min-height: 200px;
    resize: none;
  }
}