@use './../../styles/mixin';
@use './../../styles/vars';

.main {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;

    &__welcome {
        display: block;
        margin-bottom: 20px;
    }

    &__title {
        margin-bottom: 22px;

        span {
            color: vars.$secondary-color;
        }
    }

    &__text {
        padding-right: 16%;
    }

    &__fanny {
        margin-top: 35px;
    }

    &__fanny-list {
        display: flex;
        margin-top: 10px;

        li {
            margin: 10px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        max-width: 70%;

        z-index: 3;
    }

    &__image {
        display: block;
        overflow: hidden;

        max-width: 45%;

        z-index: 3;
        border-radius: 12px;

        background: linear-gradient(145deg, #6a68ce, #279ffe);
        @include mixin.box-shadow-custom;

        img {
            //max-width: 100%;
            //height: calc(100vh - 500px);

            vertical-align: middle;
            max-width: 100%;
            height: auto;

            border-radius: 12px 12px 0 0;

            background: linear-gradient(145deg, #3b516c, #0e243a);
            object-fit: cover;
        }
    }

    &__stack {
        text-align: center;
        line-height: 55px;

        font-size: .8rem;
        font-weight: 500;
        letter-spacing: 3px;
        text-transform: uppercase;

        color: #c5f7ff;
        border-radius: 0 0 12px 12px;
    }
}

@media (max-width: 790px) {
    .main {
        &__description {
            max-width: 55%;
        }

        &__image {
            max-width: 50%;
        }
    }
}

@media (max-width: 600px) {
    .main {
        flex-direction: column;
        align-items: center;

        &__message {
            text-align: center;
        }

        &__welcome {
            margin-top: 30px;
        }

        &__description {
            max-width: 100%;
        }

        &__image {
            max-width: 100%;
            margin-top: 15px;
        }

        &__fanny {
            margin-top: 25px;

            .subtitle {
                display: block;
                text-align: center;
            }
        }

        &__fanny-list {
            margin-top: 10px;
            display: flex;
            justify-content: center;
        }

        &__text {
            padding: 0;
        }
    }
}

@media (max-width: 470px) {
    .main {
        &__fanny-list {
            justify-content: space-between;
        }
    }
}