@use './../../styles/mixin';
@use './../../styles/vars';

.article {
    display: flex;
    flex-direction: column;
    font-weight: 500;

    @include mixin.paper-style(20px);
    @include mixin.paper-padding(30px 30px);

    &__image {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            background: rgba(78, 121, 185, 0.8);
            backdrop-filter: blur(4px);
            transition: 0.6s;
            border-radius: 10px;
            opacity: 0;
        }

        &:hover{
            &:before {
                opacity: 1;
            }

            .article__button {
                cursor: pointer;
                opacity: 1;
                transition: 0.4s;
            }
        }
    }

    &__button {
        position: absolute;
        top: 50%;
        left: 50%;

        font-weight: 500;
        color: #20a1ff;

        padding: 10px 25px;
        z-index: 2;

        border-radius: 6px;
        background: linear-gradient(145deg, #e2e8ec, #ffffff);
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: 0.4s;
        border: 0 none;
    }

    &__image {
        img {
            max-width: 100%;
            height: auto;
            border-radius: 10px;
            object-fit: cover;
        }
    }

    &__title {
        margin-top: 18px;

        font-weight: 500;
        font-size: .9rem;
        letter-spacing: 1px;
        text-transform: uppercase;

        color: vars.$secondary-color;
    }

    &__description {
        margin-top: 10px;

        font-size: 1.7rem;
        font-weight: 600;
        line-height: 34px;
        color: #1e2125;

        transition: .4s;
    }
}