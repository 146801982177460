.link-modern {
  width: 60px;
  height: 60px;
  line-height: 60px;
  padding: 0 !important;

  &_hover {
    &:hover {
      cursor: default !important;
    }
  }

  img {
    width: 30px !important;
  }
}