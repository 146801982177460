.skeleton {
	// width: 300px;
	border-radius: 10px;
	overflow: hidden;

	.image {
		img {
			max-width: 100%;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}
	}
}

.skeleton.is-loading {
	.image {
		background: #eee;
		background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
		background-size: 200% 100%;
		animation: 1.5s shine linear infinite;
	}

	.image {
		height: 100%;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
}

@keyframes shine {
	to {
		background-position-x: -200%;
	}
}