@use './../../styles/vars';

.work {

  &__subtitle {
    display: block;

    font-weight: 500;
    letter-spacing: 2px;
    line-height: 14px;

    color: vars.$secondary-color;
  }

  &__title {
    margin-top: 15px;
    margin-bottom: 50px;

    color: #3c3e41;
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
}


@media (max-width: 767px) {
  .work {
    &__body {
      grid-template-columns: 1fr;
    }
  }
}
