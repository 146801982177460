.wrapper, #root {
  min-height: 100vh;
}

.container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 40px;
}

.content {
  padding: 100px 0 0 0;
}

.subtitle {
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.title {
  font-weight: 700;
  font-size: 3rem;
  line-height: 75px;
}

.text {
  font-size: 1.1rem;
  line-height: 30px;

  opacity: 0.9;
}

@media (max-width: 767px) {
  .content {
    padding: 60px 0 0 0;
  }

  .container {
    padding: 0 25px;
  }
}