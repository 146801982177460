@use './../../styles/mixin';
@use './../../styles/vars';

.offer {
    @include mixin.flex-center;
    flex-direction: column;

    &__title {
        display: block;
        margin-bottom: 50px;

        color: vars.$secondary-color;
    }

    &__title {
        text-align: center;
        line-height: 2;
    }
}
